.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .rotating-image-container {
    width: 200px;
    height: 200px;
    position: relative;
  }
  
  .rotating-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: rotate 0.6s linear infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  