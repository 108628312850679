body {
  --primary-text-color: rgb(18, 30, 25);
  --secondary-text-color: rgb(206, 208, 216);
  --default-background-color: rgb(244, 244, 245);
  --product-card-background-color: white;
  --black-color: black;
  --product-card-title-color: rgb(39, 39, 41);
  --upgrade-button-color: rgb(56, 126, 246);
  --create-product-primary-button-color: #4a4ad8;
  --create-product-primary-button-hover-color: #3d3dcd;
  --create-product-primary-button-border-color: #2e2ec2;
  --blue-text-color: var(--create-product-primary-button-color);
  --unwanted-button-color: rgb(204, 63, 48);
  --billing-primary-text-color: #2e2ec2;
  --billing-promotional-text-color: green;
  --create-product-secondary-button-color: white;
  --create-product-secondary-button-hover-color: #f4f4f5;
  --create-product-secondary-button-border-color: #d4d4d8;
  --bottom-background-color: #fafafa;
  --menu-icon-color: #000000;
  --category-secondary-text-color: #71717a;
  --google-button-background-color: var(
    --create-product-secondary-button-hover-color
  );
  --gray-icon-color: #d4d4d8;
  --colored-action-text-color: #2e2ec2;
  --white-hover-color: #f4f4f5;
  --white-svg-background: white;
  --chart-background-color: rgb(56, 126, 246);
  --border-color: #e4e4e7;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--default-background-color);
  max-width: 100%;
  --borderRadiusCard: 4px;
}

header {
  background-color: white !important;
  color: rgb(101, 101, 103) !important;
}

.createNewProductButton {
  background-color: var(--create-product-primary-button-color) !important;
}

.navbarItem {
  color: rgb(39, 39, 41) !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
